@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Licorice&family=Ms+Madi&display=swap');


.active{
  font-weight: bold;
}

body {
  --accent: #ddd9ff;
  --darkeraccent:#cbc6fd;
  --lightest: #f8f6ff;
  --light: #f2f2fd;
  --darkest: #1e2749;
  --dark: #38467f;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  background: var(--light);
  padding: 0;
  margin: 0;
  font-family: 'Poppins', 'sans-serif';
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--light);
}

::-webkit-scrollbar-thumb {
  background: lightgrey;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--darkeraccent);
}